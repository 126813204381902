import React, { useState, useRef, forwardRef, useEffect } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import useControllableState from '../hooks/useControllableState';
import useMergedRef from '../hooks/useMergeRef';
import Calendar from './Calendar';
import BasePicker from './BasePicker';
import { useConfig } from '../ConfigProvider';
import capitalize from '../utils/capitalize';
import { useTranslation } from 'react-i18next';
dayjs.extend(customParseFormat);

const DEFAULT_INPUT_FORMAT = 'DD/MM/YYYY HH:mm';

const DatePicker = forwardRef((props, ref) => {
  const {
    className,
    clearable,
    clearButton,
    closePickerOnChange,
    dateViewCount,
    dayClassName,
    dayStyle,
    defaultMonth,
    defaultOpen,
    defaultValue,
    defaultView,
    disabled,
    disableDate,
    enableHeaderLabel,
    disableOutOfMonth,
    firstDayOfWeek,
    hideOutOfMonthDates,
    hideWeekdays,
    inputFormat,
    inputPrefix,
    inputSuffix,
    inputtable,
    labelFormat,
    locale,
    maxDate,
    minDate,
    name,
    onBlur,
    onChange,
    onFocus,
    onDropdownClose,
    onDropdownOpen,
    openPickerOnClear,
    renderDay,
    size,
    style,
    type,
    value,
    weekendDays,
    yearLabelFormat,
    ...rest
  } = props;

  const { t } = useTranslation();
  const { locale: themeLocale } = useConfig();
  const finalLocale = locale || themeLocale;

  const dateFormat = type === 'date' ? 'DD/MM/YYYY' : inputFormat || DEFAULT_INPUT_FORMAT;
  const [dropdownOpened, setDropdownOpened] = useState(defaultOpen);
  const inputRef = useRef();

  const [lastValidValue, setLastValidValue] = useState(defaultValue ?? null);

  const [_value, setValue] = useControllableState({
    prop: value,
    defaultProp: defaultValue,
    onChange,
  });

  const [calendarMonth, setCalendarMonth] = useState(_value || defaultMonth || new Date());
  const [focused, setFocused] = useState(false);
  const [inputState, setInputState] = useState(
    _value instanceof Date ? capitalize(dayjs(_value).locale(finalLocale).format(dateFormat)) : ''
  );

  const [shake, setShake] = useState(false);

  const [alertMsg, setAlertMsg] = useState('');

  const closeDropdown = () => {
    setDropdownOpened(false);
    onDropdownClose?.();
  };

  const openDropdown = () => {
    setDropdownOpened(true);
    onDropdownOpen?.();
  };

  useEffect(() => {
    if (value === null && !focused) {
      setInputState('');
    }
    if (value instanceof Date && !focused) {
      setInputState(capitalize(dayjs(value).locale(finalLocale).format(dateFormat)));
    }
  }, [value, focused, themeLocale]);

  useEffect(() => {
    if (defaultValue instanceof Date && inputState && !focused) {
      setInputState(capitalize(dayjs(_value).locale(finalLocale).format(dateFormat)));
    }
  }, [themeLocale]);

  const parseDate = (dateStr) => {
    return dayjs(dateStr, ['DD/MM/YYYY'], finalLocale).isValid()
      ? dayjs(dateStr, ['DD/MM/YYYY'], finalLocale).toDate()
      : null;
  };

  const setDateFromInput = () => {
    let date = typeof _value === 'string' ? parseDate(_value) : _value;
    if (maxDate && dayjs(date).isAfter(maxDate)) {
      date = maxDate;
    }
    if (minDate && dayjs(date).isBefore(minDate)) {
      date = minDate;
    }
    if (dayjs(date).isValid()) {
      setValue(date);
      setLastValidValue(date);
      setInputState(capitalize(dayjs(date).locale(finalLocale).format(dateFormat)));
      setCalendarMonth(date);
    } else {
      setValue(lastValidValue);
    }
  };

  const handleInputBlur = (event) => {
    typeof onBlur === 'function' && onBlur(event);
    setFocused(false);
    if (inputtable) {
      setDateFromInput();
    }
  };

  const handleKeyDown = (event) => {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Tab'];
    const isNumber = /\d/.test(event.key);
    if (!isNumber && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
    if (event.key === 'Enter' && inputtable) {
      closeDropdown();
      setDateFromInput();
    }
  };

  const handleInputFocus = (event) => {
    typeof onFocus === 'function' && onFocus(event);
    setFocused(true);
  };

  const formatInput = (value) => {
    const digits = value.replace(/\D/g, '').slice(0, 8);
    let formatted = digits;

    if (digits.length > 2) {
      formatted = digits.slice(0, 2) + '/' + digits.slice(2);
    }

    if (digits.length > 4) {
      formatted = formatted.slice(0, 5) + '/' + formatted.slice(5);
    }

    return formatted;
  };

  const triggerShake = () => {
    setShake(true);
    setTimeout(() => setShake(false), 300);
  };

  const handleChange = (event) => {
    openDropdown();
    const rawValue = event.target.value;
    const formatted = formatInput(rawValue);

    setInputState(formatted);

    if (formatted.length === 10) {
      const [day, month, year] = formatted.split('/');
      if (!dayjs(`${day}/${month}/${year}`, 'DD/MM/YYYY', true).isValid()) {
        setAlertMsg(t('translation.thisDateDoesntExist'));
        triggerShake();
      } else {
        setAlertMsg('');
        const date = parseDate(formatted);
        if (dayjs(date).isValid()) {
          setValue(date);
          setLastValidValue(date);
          setCalendarMonth(date);
        } else {
          setAlertMsg(t('translation.thisDateDoesntExist'));
          triggerShake();
        }
      }
    } else if (formatted.length === 0) {
      setAlertMsg('');
      setValue(null);
    } else {
      setAlertMsg('');
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <BasePicker
        inputtable={inputtable}
        dropdownOpened={dropdownOpened}
        setDropdownOpened={setDropdownOpened}
        ref={useMergedRef(ref, inputRef)}
        size={size}
        style={style}
        className={`${className || ''} ${shake ? 'shake' : ''}`}
        onChange={handleChange}
        onBlur={handleInputBlur}
        onFocus={handleInputFocus}
        onKeyDown={handleKeyDown}
        name={name}
        inputLabel={inputState}
        clearable={type === 'date' ? false : clearable && !!_value && !disabled}
        clearButton={clearButton}
        onClear={() => {
          setValue(null);
          setLastValidValue(null);
          setInputState('');
          setAlertMsg('');
          openPickerOnClear && openDropdown();
          inputRef.current?.focus();
        }}
        disabled={disabled}
        onDropdownClose={onDropdownClose}
        onDropdownOpen={onDropdownOpen}
        type={type}
        inputPrefix={inputPrefix}
        inputSuffix={inputSuffix}
        {...rest}
      >
        <Calendar
          locale={finalLocale}
          month={inputtable ? calendarMonth : undefined}
          defaultMonth={defaultMonth || (_value instanceof Date ? _value : new Date())}
          onMonthChange={setCalendarMonth}
          value={_value instanceof Date ? _value : _value && dayjs(_value).toDate()}
          onChange={(date) => {
            setValue(date);
            setInputState(capitalize(dayjs(date).locale(finalLocale).format(dateFormat)));
            closePickerOnChange && closeDropdown();
            window.setTimeout(() => inputRef.current?.focus(), 0);
          }}
          labelFormat={labelFormat}
          dayClassName={dayClassName}
          dayStyle={dayStyle}
          disableOutOfMonth={disableOutOfMonth}
          minDate={minDate}
          maxDate={maxDate}
          disableDate={disableDate}
          firstDayOfWeek={firstDayOfWeek}
          preventFocus={inputtable}
          dateViewCount={dateViewCount}
          enableHeaderLabel={enableHeaderLabel}
          defaultView={defaultView}
          hideOutOfMonthDates={hideOutOfMonthDates}
          hideWeekdays={hideWeekdays}
          renderDay={renderDay}
          weekendDays={weekendDays}
          yearLabelFormat={yearLabelFormat}
        />
      </BasePicker>

      {alertMsg && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            color: 'red',
            background: '#fff',
            padding: '5px',
            marginTop: '1px',
            zIndex: 999,
          }}
        >
          {alertMsg}
        </div>
      )}
    </div>
  );
});

DatePicker.defaultProps = {
  closePickerOnChange: true,
  labelFormat: {
    month: 'MMM',
    year: 'YYYY',
  },
  defaultOpen: false,
  name: 'date',
  clearable: true,
  disabled: false,
  firstDayOfWeek: 'monday',
  openPickerOnClear: false,
};

export default DatePicker;
